import * as React from "react";
import I18N from "../../common/i18n/I18N";
import User from "../../common/User";
import Advertisement from "../advertisement";
import IApplicationProps from "../application-collection/IApplicationProps";
import NavigationBar from "../navigation-bar/NavigationBar";
import "./Style.scss";
import Wallpaper from "./Wallpaper";

interface ILink {
  href: string;
  title: string;
  description?: string;
  visible?: boolean;
}

export default class Launcher extends React.Component<IApplicationProps> {
  componentDidMount() {
    if (User.IsGuest && User.HasSessionKey()) window.location.hash = "#login";
  }

  renderSection(params: { title: string; links: ILink[] }) {
    const visibleNumber = params.links.reduce(
      (total, link) => total + (link.visible === false ? 0 : 1),
      0
    );

    if (visibleNumber > 0) {
      return (
        <section>
          <header>{params.title}</header>

          <div className="links">
            {params.links.map(
              (link, index) =>
                link.visible !== false && (
                  <a
                    href={link.href !== "#invalid" ? link.href : undefined}
                    key={index}
                  >
                    <header>{link.title}</header>

                    {"description" in link && (
                      <div>
                        <div style={{ marginTop: 5 }}>{link.description}</div>
                      </div>
                    )}
                  </a>
                )
            )}
          </div>
        </section>
      );
    }
  }

  render() {
    return (
      <div className="Launcher root">
        <Wallpaper />

        <div className="wrapper">
          <div style={{ textAlign: "right" }}>
            <img src="./SICH_LOGO_V6.svg" alt="SICH Logo" />
          </div>

          {this.renderSection({
            title: I18N.APPS,
            links: [
              {
                href: "#companies",
                title: I18N.COMPANIES,
                description: I18N.SEE_THE_LIST_OF_ALL_COMPANIES_IN_SICH,
              },
              {
                href: "#capacity-calendar",
                title: I18N.TASK_SHARING,
                description: I18N.MANAGE_THE_CAPACITY_REQUESTSOFFERS,
              },
              {
                href: "#request-registration-list",
                title: I18N.REGISTRATION_REQUESTS,
                // description: I18N.MANAGE_THE_CAPACITY_REQUESTSOFFERS,
                visible: User.IsGod || User.IsAdmin,
              },
              {
                href: "https://holz-saentis.slack.com/app_redirect?channel=general",
                title: I18N.CHAT,
                description: I18N.OPEN_SICH_WORKSPACE_ON_SLACK,
              },
              {
                href: "#login",
                title: I18N.LOGIN,
                visible: User.IsGuest,
              },
              {
                href: "#request-registration",
                title: I18N.SIGN_UP,
                visible: User.IsGuest,
              },
            ],
          })}

          {/*<Advertisement />*/}
          <section>
            <header>{I18N.PARTNERS}</header>

            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <img src="./partner/Arnold Hohl AG.png" width="105" />
              <img src="./partner/Braun Gossau.png" width="105" />
              <img src="./partner/Cadwork Holz AG.png" width="105" />
              <img src="./partner/ibw.png" width="105" />
              <img src="./partner/Kuratle Jäcker AG.png" width="105" />
              <img src="./partner/tw-logo.jpg" width="105" />
            </div>
          </section>

          {this.renderSection({
            title: I18N.MODIFY,
            links: [
              {
                href: `#user-edit_${User.Instance.id}`,
                title: I18N.MY_PROFILE,
                description: I18N.UPDATE_YOUR_PERSONAL_INFORMATION,
                visible: !User.IsGuest,
              },
              {
                visible: User.IsGod || User.IsAdmin,
                href: `#company-edit_${User.Instance.company_id}`,
                title: I18N.MY_COMPANY,
                description: I18N.UPDATE_YOUR_COMPANY_INFORMATION,
              },
              {
                // visible: User.IsGod || User.IsAdmin,
                href: "#users",
                title: I18N.USERS,
                description: I18N.SEE_A_LIST_OF_USERS,
                visible: !User.IsGuest,
              },
              {
                visible: User.IsGod || User.IsAdmin,
                href: `#file-list`,
                title: I18N.PHOTOS,
                description: I18N.MANAGE_YOUR_UPLOADED_FILES,
              },
              {
                visible: User.IsGod,
                href: `#regions`,
                title: I18N.REGIONS,
                description: I18N.EDIT_THE_REGIONS,
              },
              {
                visible: User.IsGod,
                href: `#services`,
                title: I18N.SERVICES,
                description: I18N.EDIT_THE_SERVICES,
              },
            ],
          })}

          <NavigationBar visible={true} in_launcher />
        </div>
      </div>
    );
  }
}
